import React, { useState, useEffect } from 'react';
import Image from '@atoms/Image';
import SvgIconBox from '@atoms/SvgIconBox';
import PollWithOptions from '../../public/images/InteractionPage/dynamic-svg/poll.svg';
import MapIcon from '../../public/images/InteractionPage/dynamic-svg/map.svg';
import WordcloudIcon from '../../public/images/InteractionPage/dynamic-svg/wordcloud.svg';
import PollIcon from '../../public/images/InteractionPage/dynamic-svg/pollff.svg';
import CircleIcon from '../../public/images/InteractionPage/dynamic-svg/circle.svg';
import ThoughtsIcon from '../../public/images/InteractionPage/dynamic-svg/thoughts.svg';
import WheelIcon from '../../public/images/InteractionPage/dynamic-svg/wheel.svg';
import QuickQuestionIcon from '../../public/images/InteractionPage/dynamic-svg/questions.svg';
import LinkLibraryIcon from '../../public/images/InteractionPage/dynamic-svg/link-library.svg';
import EmojisEverywhereIcon from '../../public/images/InteractionPage/dynamic-svg/emojiEverywhere.svg';
import TextTrackIcon from '../../public/images/InteractionPage/dynamic-svg/text-track.svg';
import TalkingTilesIcon from '../../public/images/InteractionPage/dynamic-svg/talkingTiles.svg';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import RatingPollsIcon from '../../public/images/InteractionPage/dynamic-svg/pulsing-points.svg';
import MultipleChoiceIcon from '../../public/images/InteractionPage/dynamic-svg/multiple-choice-colored.svg';
import InteractionSettings from '../../public/images/interaction_settings.svg';
import { getLiveLayoutColors } from './helper/presentationLive';
import useFonts from '@lib/hooks/useFonts';

const InteractionTitle = ({
  title,
  titleFont = 'Sans-Serif',
  titleColor = '#FFFFFF',
  type,
  logoUrl,
  slideUrl,
  isPreview = false,
  isLogoRequired = false,
  showGrid = false,
  layoutTheme = 'dark'
}) => {
  const [fontSize, setFontSize] = useState('xl:text-6xl leading-10 lg:text-6xl leading-8');
  const { getFontStyle } = useFonts();
  const [fontStyle, setFontStyle] = useState(getFontStyle(titleFont));
  const colors = getLiveLayoutColors(layoutTheme);

  const size = {
    small: 'text-3xl',
    medium: 'text-4xl',
    large: 'text-5xl'
  };

  const createInteraction = (icon, color, text1, interactionTitle) => {
    const commonProps = {
      color: `text-interactionIcons-${color}`,
      text1,
      interactionTitle
    };

    if (icon) {
      commonProps.icon = React.cloneElement(icon, {
        width: '26',
        height: '26',
        className: `fill-current text-interactionIcons-${color}`
      });
    }

    return commonProps;
  };

  const interactionsMap = {
    pollWithOptions: createInteraction(<PollWithOptions />, 'PollWithOptions', 'Power Poll With Options', title),
    map: createInteraction(<MapIcon />, 'MagicMap', 'Magic Maps', title),
    wordCloud: createInteraction(<WordcloudIcon />, 'WonderWords', 'Wonder Words', title),
    freeForm: createInteraction(<PollIcon />, 'PollWithOutOptions', 'Power Poll Without Options', title),
    wheel: createInteraction(<span style={{ width: '32px', height: '32px' }}><CircleIcon /></span>, 'ChoiceCircle', 'Choice Circle', title),
    transientThought: createInteraction(<ThoughtsIcon />, 'TransientThoughts', 'Transient Thoughts', title),
    newWheel: createInteraction(<span style={{ width: '32px', height: '32px' }}><WheelIcon /></span>, 'WinnersWheel', 'Winning Wheel', title),
    emojisEverywhere: createInteraction(<EmojisEverywhereIcon />, 'EmojisEverywhere', 'Emojis Everywhere', title),
    talkingTiles: createInteraction(<TalkingTilesIcon />, 'TalkingTiles', 'Talking Tiles', title),
    'Quick Questions': createInteraction(<QuickQuestionIcon />, 'QuickQuestions', 'Quick Questions', title),
    linkLibrary: createInteraction(<LinkLibraryIcon />, 'linkLibrary', 'Link Library', title),
    textTrack: createInteraction(<TextTrackIcon />, 'TextTrack', 'Text Track', title),
    'PDF Slide': createInteraction(null, 'PdfSlide', 'Slide', title),
    ratingPolls: createInteraction(<RatingPollsIcon />, 'ratingPolls', 'Rating Polls', title),
    fantasticFans: createInteraction(<Groups2RoundedIcon />, 'FantasticFans', 'Fantastic Fans', title),
    multipleChoice: createInteraction(<span className='h-7 w-7 flex justify-center items-center'><MultipleChoiceIcon/></span>, 'MultipleChoice', 'Multiple Choice', title)
  };

  const updateFontSize = () => {
    const { small, medium, large } = size;
    const element = document.getElementById('hidden');
    if (!element) return;
    const lineHeight = parseInt(getComputedStyle(element).lineHeight, 10);
    const elementHeight = element.scrollHeight;
    const lines = Math.round(elementHeight / lineHeight);
    if (lines === 1) {
      setFontSize(large);
    } else if (lines === 2) {
      setFontSize(medium);
    } else {
      setFontSize(small);
    }
  };

  useEffect(() => {
    updateFontSize();
  }, [title]);

  useEffect(() => {
    setFontStyle(getFontStyle(titleFont));
  }, [titleFont]);

  if (!type) return null;

  const {
    color, icon, text1, interactionTitle
  } = interactionsMap[type];

  return (
    <>
      {showGrid ? <div className='w-full'>
        {isPreview && type === 'PDF Slide' ? <Image src={slideUrl} className={`w-[200px] h-[113.23px] flex-shrink-0 border border-neutral-10  rounded-lg  ${['dummy-image', 'failed-image'].some((substring) => slideUrl.includes(substring)) && 'object-cover'}`} /> : <SvgIconBox svg={icon} className={`w-[200px] h-[113.23px] ${colors.containerColor} flex-shrink-0 border border-neutral-10 rounded-lg`} disablePointer={true} />}

        <div className='flex flex-col py-3'>
          {isPreview && <div className={`${color} text-center font-Inter font-medium text-xs leading-4`}>{text1}</div>}

          <SvgIconBox
            showDarkToolTip={true}
            id='interaction-title-tooltip'
            toolTipText={interactionTitle.replace(/(.{26})/g, '$1-')}
            place='bottom'
            className={'border-focused w-full h-full'}
            disablePointer={true}
            text={<div className='w-full text-center truncate overflow-hidden'>{interactionTitle}</div>}
          />
        </div>

      </div>
        : <div className='flex justify-center items-start gap-x-4'>
          <div
            style={fontStyle}
            className='flex flex-col flex-grow'>
            {isPreview && <div className={`${color}  font-medium text-xs leading-4`}>{text1}</div>}

            {interactionTitle
              && (
                <>
                  <p id='hidden' className='hidden-div xl:text-3xl xl:leading-6 text-lg leading-5 dark:text-white font-normal  break-normal '>
                    {interactionTitle.split(' ').map((word) => (word.length > 26 ? word.replace(/(.{26})/g, '$1-') : word)).join(' ')}
                  </p>
                  <p id='visible' style={{ color: titleColor }} className={`${!isPreview ? fontSize : 'text-base'} dark:text-white font-semibold py-1 break-normal !leading-tight ${!isPreview ? 'line-clamp-4' : 'line-clamp-2'} `}>
                    {interactionTitle.split(' ').map((word) => (word.length > 26 ? word.replace(/(.{26})/g, '$1-') : word)).join(' ')}
                  </p>
                </>
              )}
          </div>
          <div className="relative py-2 rounded-md">
            {isLogoRequired && (<Image
              className="inline-block max-w-brand-logo-sm max-h-brand-logo-sm flex-shrink-0 pl-10"
              src={logoUrl}
            />
            )}
          </div>
        </div>
      }
    </>);
};

export default InteractionTitle;
