export function getLiveLayoutColors(theme: 'dark' | 'light') {
  if (theme === 'dark') {
    return {
      textColor: 'text-white',
      svgColor: 'text-white',
      svgActiveColor: 'text-grey-900',
      containerColor: 'bg-grey-900 shadow',
      boxColor: 'bg-white',
      hoverBg: 'hover:bg-grey-800',
    };
  }
  return {
    textColor: 'text-grey-900',
    svgColor: 'text-grey-900',
    svgActiveColor: 'text-grey-900',
    containerColor: 'bg-grey-50 shadow',
    boxColor: 'bg-grey-200',
    hoverBg: 'hover:bg-grey-200',
  };
};
